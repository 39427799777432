.google-map {


    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;

}

.HomeContent-Container {
    display: flex;
    gap: 1.5rem;
}