.image-upload.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image-upload {

    margin-bottom: 1rem;
}

.image-upload__preview {
    width: 10rem;
    height: 10rem;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
}

.image-upload__preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}