.LostCatImage-Container {
    margin-left: 10px;
    width: 21%;
    padding: 10px;
    background-color: rgba(225, 225, 225, 0.864);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

img {

    width: 100%;
}

.lostcatpage-container {

    display: flex;
    gap: 1.5rem;

}

.LostCatDesc-Container {
    width: 79%;
    padding: 10px;
    background-color: rgba(225, 225, 225, 0.864);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
    margin-right: 10px;

}

hr {

    border: .5px solid rgba(163, 162, 162, 0.864);
    width: 90%;
    margin: auto;
}