.Lost-Button {
    background-color: purple;
    color: white;
    border-radius: 10px;
    text-decoration: none;
    padding: 8px 40px;
    appearance: none;
    border: 0;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 3px 8px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.LostButton-Container {
    margin: 30px 0px;
    display: flex;
    justify-content: center;
    gap: 1rem;
}